<template>
  <div>
    <TourNavbar />
    <div class="container">
      <b-row class="flex-wrap justify-content-center stepper-wrapper">
        <!-- Basic Info -->
        <b-col
          sm="12"
          md="2"
        >
          <b-link
            class="d-flex align-items-center stepper-link"
            active-class="active"
            :to="tourId ? `/tour-register/step-1/${tourId}` : '/tour-register/step-1/new'"
          >
            <div class="icon-wrapper">
              <i class="far fa-file-alt" />
            </div>
            <span class="pl-1">Basic Info</span>
          </b-link>
        </b-col>
        <!-- Policies -->
        <b-col
          sm="12"
          md="2"
        >
          <b-link
            class="d-flex align-items-center stepper-link"
            active-class="active"
            :disabled="!tourId"
            :to="tourId ? `/tour-register/step-2/${tourId}`: ''"
          >
            <div class="icon-wrapper">
              <i class="fas fa-feather" />
            </div>
            <span class="pl-1">Policies</span>
          </b-link>
        </b-col>
        <!-- Tags -->
        <b-col
          sm="12"
          md="2"
        >
          <b-link
            class="d-flex align-items-center stepper-link"
            active-class="active"
            :disabled="!tourId"
            :to="tourId ? `/tour-register/step-3/${tourId}`: ''"
          >
            <div class="icon-wrapper">
              <i class="fas fa-tags" />
            </div>
            <span class="pl-1">Tags</span>
          </b-link>
        </b-col>
        <!-- Photos -->
        <b-col
          sm="12"
          md="2"
        >
          <b-link
            class="d-flex align-items-center stepper-link"
            active-class="active"
            :disabled="!tourId"
            :to="tourId ? `/tour-register/step-4/${tourId}`: ''"
          >
            <div class="icon-wrapper">
              <i class="fas fa-image" />
            </div>
            <span class="pl-1">Photos</span>
          </b-link>
        </b-col>
        <!-- Itinerary -->
        <b-col
          sm="12"
          md="2"
        >
          <b-link
            class="d-flex align-items-center stepper-link"
            active-class="active"
            :disabled="!tourId"
            :to="tourId ? `/tour-register/step-5/${tourId}`: ''"
          >
            <div class="icon-wrapper">
              <i class="fas fa-clipboard-list" />
            </div>
            <span class="pl-1">Itinerary</span>
          </b-link>
        </b-col>
        <!-- Options -->
        <b-col
          sm="12"
          md="2"
        >
          <b-link
            class="d-flex align-items-center stepper-link"
            active-class="active"
            :disabled="!tourId"
            :to="tourId ? `/tour-register/step-6/${tourId}`: ''"
          >
            <div class="icon-wrapper">
              <i class="fas fa-stream" />
            </div>
            <span class="pl-1">Options</span>
          </b-link>
        </b-col>
      </b-row>
      <!-- Home Button -->
      <div class="home-button p-1">
        <b-button
          variant="flat-primary"
          to="/"
        >
          <feather-icon
            icon="HomeIcon"
            class="mr-25"
          />
          <span class="align-middle">Home</span>
        </b-button>
        <b-button
          v-if="tourId"
          variant="flat-warning"
          :to="`/availability/${$route.params.id}`"
          target="_blank"
        >
          <feather-icon
            icon="CalendarIcon"
            class="mr-25"
          />
          <span class="align-middle">Availability</span>
        </b-button>
        <b-button
          v-if="tourId"
          class="float-md-right"
          variant="flat-primary"
          @click="redirectToWeb($route.params.id)"
        >
          <feather-icon icon="RssIcon" />
          <span class="align-middle">Live Preview</span>
        </b-button>
      </div>
      <b-row
        class="py-1 justify-content-center"
      >
        <b-col
          md="8"
          sm="12"
        >
          <h3 class="text-primary font-weight-bolder mb-1">
            {{ $store.state.tour.property.name }}
          </h3>
        </b-col>

        <b-col
          md="8"
          sm="12"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <router-view />
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TourNavbar from './TourNavbar.vue'
import tour from '@/mixins/tour'

export default {
  name: 'TourOnBoarding',
  components: {
    TourNavbar,
  },
  mixins: [tour],
  data() {
    return {
      // tourId: null,
    }
  },
  computed: {
    tourId() {
      if (this.$route.params.id !== 'new') {
        return this.$route.params.id
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
.stepper-wrapper {
  background-color: #fff;
  margin: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgb(34 41 47 / 10%);
  .stepper-link {
    margin: 10px 0;
    .icon-wrapper {
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: #ededed;
      i {
        color: #b3bcc6;
      }
    }
    span {
      font-weight: 600;
      color: #b3bcc6;
    }
    &.active {
      .icon-wrapper {
        background-color: #7367f0;
        i {
          color: #fff;
        }
      }
      span {
        color: #7367f0;
      }
    }
  }
}
.dark-layout {
  .stepper-wrapper {
    background-color: #262D3E;
    .stepper-link {
      .icon-wrapper {
        background-color: #354159;
      }
      &.active {
        .icon-wrapper {
          background-color: #7367f0;
        }
      }
    }
  }
}
</style>
